/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { graphql } from 'gatsby';
import { Global } from '@emotion/core';
import theme from '../utils/theme';
import { scaffoldingStyles } from '../utils/scaffolding';
import { mt } from '../utils/classes';
import {
  SEO,
  Container,
  TopNavigation,
  Section,
  Heading,
  Footer,
  TextAccent,
  Link,
  ArrowExternal,
} from '../components';
import BlogItem from '../compositions/BlogListing/BlogItem';
import { Wrapper as SectionWrapper, BlogList } from '../compositions/BlogListing/BlogListing.style';

/**
 * Tags page
 */
const Tags = ({ pageContext, data, path }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `#${tag} blog post${totalCount === 1 ? '' : 's'}`;
  const isHeyPolls = tag === 'heypolls';

  return (
    <ThemeProvider theme={theme}>
      <SEO title={`${tag} posts | Danijel Grabež`} pagePath={path} />
      <Global styles={scaffoldingStyles} />
      <Container>
        <TopNavigation to="/blog" title="Writings" />
        <SectionWrapper>
          <Section sidebar={<div />}>
            <div>
              <Heading element="h1" colorKind="brand">
                {tagHeader}
              </Heading>
              {isHeyPolls && (
                <TextAccent>
                  Interesting details and thoughts documented while building{' '}
                  <Link to="https://heypolls.com/">
                    Hey, Polls!
                    <ArrowExternal />
                  </Link>
                </TextAccent>
              )}
              <BlogList css={mt(60)}>
                {edges.map(({ node }) => {
                  const { slug } = node.fields;

                  return <BlogItem frontmatter={node.frontmatter} slug={slug} key={slug} />;
                })}
              </BlogList>
            </div>
          </Section>
          <div style={{ marginTop: 'auto' }}>
            <Footer showBio={false} />
          </div>
        </SectionWrapper>
      </Container>
    </ThemeProvider>
  );
};

Tags.propTypes = {
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              inlineSubtitle: PropTypes.string,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }).isRequired,
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            inlineSubtitle
            excerpt
            tags
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            postKind
            blogThemeConfig {
              color
              background
            }
          }
        }
      }
    }
  }
`;
